import React from 'react'

import userImg from '../../user.jpg'
import bgImg from '../../bg.jpg'

const FriendsList = () => {
    return (
        <div className="container">
  <div className="row">
  <div class="col-md-3"></div>
   <div className="col-md-9">
      {/* Post Create Box
      ================================================= */}
      <div className="create-post">
        <div className="row">
          <div className="col-md-7 col-sm-7">
            <div className="form-group">
              <img
                src={userImg}
                alt=""
                className="profile-photo-md"
              />
              <textarea
                name="texts"
                id="exampleTextarea"
                cols={30}
                rows={1}
                className="form-control"
                placeholder="Write what you wish"
                defaultValue={""}
              />
            </div>
          </div>
          <div className="col-md-5 col-sm-5">
            <div className="tools">
              <ul className="publishing-tools list-inline">
                <li>
                  <a href="#">
                    <i className="ion-compose" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="ion-images" />
                  </a>
                </li>
                </ul>
              <button className="btn btn-primary pull-right">Publish</button>
            </div>
          </div>
        </div>
      </div>
      {/* Post Create Box End */}
      {/* Friend List
      ================================================= */}
      <div className="friend-list">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="friend-card">
              <img
                src={bgImg}
                alt="profile-cover"
                className="img-responsive cover"
              />
              <div className="card-info">
                <img
                  src={userImg}
                  alt="user"
                  className="profile-photo-lg"
                />
                <div className="friend-info">
                  <a href="#" className="pull-right text-green">
                    My Friend
                  </a>
                  <h5>
                    <a href="timeline.html" className="profile-link">
                      Sophia Lee
                    </a>
                  </h5>
                  <p>Student at Harvard</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="friend-card">
              <img
                src={bgImg}
                alt="profile-cover"
                className="img-responsive cover"
              />
              <div className="card-info">
                <img
                  src={userImg}
                  alt="user"
                  className="profile-photo-lg"
                />
                <div className="friend-info">
                  <a href="#" className="pull-right text-green">
                    My Friend
                  </a>
                  <h5>
                    <a href="timeline.html" className="profile-link">
                      John Doe
                    </a>
                  </h5>
                  <p>Traveler</p>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  
  
  </div>
</div>

    )
}

export default FriendsList
