import React,{useState} from 'react'

import {useFormik} from 'formik'


const EditProfile = () => {

    //0 means whichForm is basic information
    //1 means it is change password

    const [whichForm,setForm] = useState(0)


    const formik = useFormik({
      initialValues:{
        username: "",
        fullName: ""
      },
      onSubmit:(values) =>{

      }
    })

    const changeForm =(value)=>{
      if(value === 1){
        setForm(1)
      }else{
        setForm(0)
      }
    }

  
    let form 
    
if(whichForm === 0){

form = 
<>
 <div className="block-title">
          <h4 className="grey">
            <i className="icon ion-android-checkmark-circle" />
            Edit basic information
          </h4>
          <div className="line" />
          <p>
            Update your basic information below
          </p>
          <div className="line" />
        </div>
<div className="edit-block">
<form name="basic-info" id="basic-info" className="form-inline">
<div className="row">
    <div className="form-group col-xs-12">
      <label htmlFor="username">Username</label>
      <input
        id="username"
        className="form-control input-group-lg"
        type="text"
        name="Email"
        title="Enter Email"
        placeholder="My Email"
      />
    </div>
  </div>
  <div className="row">
    <div className="form-group col-xs-12">
      <label htmlFor="firstname">Full name</label>
      <input
        id="firstname"
        className="form-control input-group-lg"
        type="text"
        name="firstname"
        title="Enter first name"
        placeholder="First name"
        defaultValue="John"
        style={{
          backgroundImage: 'url("data:image/png',
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "16px 18px",
          backgroundPosition: "98% 50%",
          cursor: "auto"
        }}
      />
    </div>
   
  </div>
  <div className="row">
    <div className="form-group col-xs-12">
      <label htmlFor="email">My email</label>
      <input
        id="email"
        className="form-control input-group-lg"
        type="text"
        name="Email"
        title="Enter Email"
        placeholder="My Email"
        defaultValue="razor.venon@gmail.com"
      />
    </div>
  </div>
  
  <div className="row">
    <div className="form-group col-xs-12">
      <label htmlFor="my-info">About me</label>
      <textarea
        id="my-info"
        name="information"
        className="form-control"
        placeholder="Some texts about me"
        rows={4}
        cols={400}
        defaultValue={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur"
        }
      />
    </div>
  </div>
  <button className="btn btn-danger">Save Changes</button>
</form>
</div>
</>
}else{

form =  
<>
<div className="block-title">
<h4 className="grey">
  <i className="icon ion-android-checkmark-circle" />
  Edit your password
</h4>
<div className="line" />
<p>
  Change your password
</p>
<div className="line" />
</div>
<div className="edit-block" id="changePassword">
<form name="basic-info" id="basic-info" className="form-inline">
<div className="row">
<div className="form-group col-xs-12">
  <label htmlFor="username">Old Password</label>
  <input
    id="username"
    className="form-control input-group-lg"
    type="text"
    name="Email"
    title="Enter Email"
    placeholder="My Email"
  />
</div>
</div>
<div className="row">
<div className="form-group col-xs-12">
  <label htmlFor="firstname">New Password</label>
  <input
    id="firstname"
    className="form-control input-group-lg"
    type="text"
    name="firstname"
    defaultValue="John"
    style={{
      backgroundImage: 'url("data:image/png',
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "scroll",
      backgroundSize: "16px 18px",
      backgroundPosition: "98% 50%",
      cursor: "auto"
    }}
  />
</div>

</div>
<div className="row">
<div className="form-group col-xs-12">
  <label htmlFor="firstname">Confirm New Password</label>
  <input
    id="confPassword"
    className="form-control input-group-lg"
    type="password"
    name="confPassword"
    defaultValue="John"
    style={{
      backgroundImage: 'url("data:image/png',
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "scroll",
      backgroundSize: "16px 18px",
      backgroundPosition: "98% 50%",
      cursor: "auto"
    }}
  />
</div>

</div>


<button className="btn btn-danger">Save Changes</button>
</form>
</div>
</>
}



    return (
        <>
            <div id="page-contents" class="container">
  <div className="row">
    <div className="col-md-3">
      {/*Edit Profile Menu*/}
      <ul className="edit-menu">
        <li className="active">
          <i className="icon ion-ios-information-outline" />
          <a onClick={()=>setForm(0)} >Basic Information</a>
        </li>
        <li>
          <i className="icon ion-ios-locked-outline" />
          <a onClick={()=>setForm(1)} >Change Password</a>
        </li>
      </ul>
    </div>
    <div className="col-md-7">
      {/* Basic Information
        ================================================= */}
      <div className="edit-profile-container">
       {form}
     
      </div>
    </div>
  </div>
</div>
        </>
    )
}

export default EditProfile
