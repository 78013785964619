import React,{useState} from 'react'

import {useFormik} from 'formik'

import {Circle} from 'react-spinners-css';
import { NavLink, useHistory } from 'react-router-dom';


const HomeForm = (props) => {

    const [isFormLoading, setFormStatus] = useState({
      registerForm: false,
      loginForm: false,

})

let history =  useHistory()

    const formikSignin = useFormik({
      initialValues:{
        "username":"",
        "password": ""
      },
      onSubmit:(values)=>{
        setFormStatus({loginForm:true});
      }
    })

    //formik form for registration
    const formik = useFormik(
      {
        initialValues:{
          "name": "",
          "phoneNumber": "",
          "email": "",
          "gender": "",
          "intention": "",
          "password": "",
          "confPassword": "",
          "username": "",

          
        },
        onSubmit: values =>{
          //show loader
          setFormStatus({registerForm:true});

          //validate input

          //submit input to api
          

          history.push('/dashboard/home')
           

        }
      }
    )


    let form;

    let buttonOrLoaderRegister;
    let buttonOrLoaderLogin;
    
    if(isFormLoading.registerForm){
      buttonOrLoaderRegister = <Circle color="red"/>
    }else{
      buttonOrLoaderRegister = <button className="btn-secondary" type="submit">Signup</button>
    }


    if(isFormLoading.loginForm){
      buttonOrLoaderLogin = <Circle color="red"/>
    }else{
      buttonOrLoaderLogin =<button className="btn-secondary" type="submit">Sign In</button>
    }




    if(props.islogin){
        form = <div className="sign-up-form">
        <NavLink to="/" className="logo">
          <img src="images/logo.png" alt="Trafficate" />
        </NavLink>
        <h2 className="text-white" style={{marginTop: "10px"}} >Find My Friends</h2>
        <div className="line-divider" />
        <div className="form-wrapper">
          <p className="signup-text">
            Sign into your account now and meet awesome people around the world
          </p>
          <form onSubmit={formikSignin.handleSubmit}>
       
        <fieldset className="form-group">
          <input
            type="text"
            className="form-control"
            id="username"
            placeholder="Enter email or username"
            {...formikSignin.getFieldProps('username')}
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            autoComplete="off"
          />
        </fieldset>
        <fieldset className="form-group">
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            {...formikSignin.getFieldProps('password')}
            placeholder="Enter a password"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            autoComplete="off"
          />
        </fieldset>

        <p>By signing up you agree to the terms</p>
      {buttonOrLoaderLogin}
      </form>
          {/* Sign Up Form End */}
        </div>
        <a onClick={props.changeFormType}>Create An Account?</a>
        <img className="form-shadow" src="images/bottom-shadow.png" alt="" />
      </div>
    //this is the sign in form
        
    }else{
        //signup
       form = <div className="sign-up-form">
        <NavLink to="/" className="logo">
          <img src="images/logo.png" alt="Trafficate" />
        </NavLink>
        <h2 className="text-white" style={{marginTop: "10px"}}>Find My Friends</h2>
        <div className="line-divider" />
        <div className="form-wrapper">
          <p className="signup-text">
            Signup now and meet awesome people around the world
          </p>
    <form onSubmit={formik.handleSubmit}>
      {/* <Circle color="red"/> */}
        <fieldset className="form-group">
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Enter name"
            {...formik.getFieldProps('name')}
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            autoComplete="off"
          />
        </fieldset>
        <fieldset className="form-group">
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Enter phone number"
            {...formik.getFieldProps('phoneNumber')}
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            autoComplete="off"
          />
        </fieldset>
        <fieldset className="form-group">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            {...formik.getFieldProps('email')}
            placeholder="Enter email"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            autoComplete="off"
          />
        </fieldset>
        <fieldset className="form-group">
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            {...formik.getFieldProps('username')}
            placeholder="Enter username"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
          />
        </fieldset>
        <fieldset className="form-group">
            {/* <label>Gender:</label> */}
            <select className="form-control" name="gender" id="gender" {...formik.getFieldProps('gender')} required>
            <option value="">Select Gender</option>
            <option value="female">Female</option>
                <option value="male">Male</option>
            </select>
          
        </fieldset>
        <fieldset className="form-group">
            {/* <label>Intention:</label> */}
            <select className="form-control" name="intention" id="intention" {...formik.getFieldProps('intention')}>
            <option value="">Please select your intention</option>
            <option value="0">I'm Available</option>
                <option value="1">Maybe, you can take a chance</option>
                <option value="2">Taken, just here for me</option>
            </select>
          
        </fieldset>
        <fieldset className="form-group">
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            {...formik.getFieldProps('password')}
            placeholder="Enter a password"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            autoComplete="off"
          />
        </fieldset>
        <fieldset className="form-group">
          <input
            type="password"
            className="form-control"
            id="confPassword"
            name="confPassword"
            {...formik.getFieldProps('confPassword')}
            placeholder="Confirm password"
            style={{
              backgroundImage: 'url("data:image/png',
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "16px 18px",
              backgroundPosition: "98% 50%"
            }}
            autoComplete="off"
          />
        </fieldset>

     {buttonOrLoaderRegister}
      </form>
          {/* Sign Up Form End */}
          <p>By signing up you agree to the terms</p>
        </div>
        <button type="button" className="btn btn-link" onClick={props.changeFormType}>Already have an account?</button>
        <img className="form-shadow" src="images/bottom-shadow.png" alt="" />
      </div>

        
      
    }

    return (
        <section id="banner">
  <div className="container">
    {/* Sign Up Form
  ================================================= */}
   {form}
   
    <svg className="arrows hidden-xs hidden-sm">
      <path className="a1" d="M0 0 L30 32 L60 0" />
      <path className="a2" d="M0 20 L30 52 L60 20" />
      <path className="a3" d="M0 40 L30 72 L60 40" />
    </svg>
  </div>
</section>

    )
}

export default HomeForm
