import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './pages/Home';

import DashboardHome from './pages/dashboard/DashboardHome';
// import NewsFeed from './pages/dashboard/NewsFeed';

// import EditProfile from './pages/dashboard/EditProfile';

import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route,Switch} from 'react-router-dom';

import NotFound from './NotFound'

ReactDOM.render(
  <React.StrictMode>
    
    <Router>
  <Switch>
      <Route path="/" exact component={Home}/>
      <Route path="/dashboard/home" component={DashboardHome} />
      {/* <Route path="/dashboard/newsfeed" component={NewsFeed} /> */}
      {/* <Route path="/dashboard/editme" component={EditProfile} /> */}
      <Route component={NotFound}/>
      
      </Switch>
    </Router>
    
    
    



  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
