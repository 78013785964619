import React from 'react'

import HomeHeader from './components/HomeHeader'
import HomeFooter from './components/HomeFooter'

import blackLogo from './blackLogo.png'
import { NavLink } from 'react-router-dom'


const NotFound = () => {
    return (
        <>
        <HomeHeader/>
            <div className="error-page">
  <div className="error-content">
    <div className="container">
      <img src={blackLogo} alt className="img-responsive" width="50%"/>
      <div className="error-message">
        <h1 className="error-title">Whoops!</h1>
        <p>Looks like you are lost. But don't worry there is plenty to see!!</p>
      </div>
      <form className="search-form">
        
        <NavLink to="/" className="btn btn-primary">
          Go Home!
        </NavLink>
      </form>
    </div>
  </div>
</div>
<HomeFooter />
        </>
    )
}

export default NotFound
