import React from 'react'

const HomeBody = () => {
    return (
        <>
         <div>
  {/* Features Section
    ================================================= */}
  <section id="features">
    <div className="container wrapper">
      <h1 className="section-title slideDown appear">social herd</h1>
      <div className="row slideUp appear">
        <div className="feature-item col-md-2 col-sm-6 col-xs-6 col-md-offset-2">
          <div className="feature-icon">
            <i className="icon ion-person-add" />
          </div>
          <h3>Make Friends</h3>
        </div>
        <div className="feature-item col-md-2 col-sm-6 col-xs-6">
          <div className="feature-icon">
            <i className="icon ion-images" />
          </div>
          <h3>Publish Posts</h3>
        </div>
        <div className="feature-item col-md-2 col-sm-6 col-xs-6">
          <div className="feature-icon">
            <i className="icon ion-chatbox-working" />
          </div>
          <h3>Private Chats</h3>
        </div>
        <div className="feature-item col-md-2 col-sm-6 col-xs-6">
          <div className="feature-icon">
            <i className="icon ion-compose" />
          </div>
          <h3>Create Polls</h3>
        </div>
      </div>
      <h2 className="sub-title">find awesome people like you</h2>
      <div id="incremental-counter" data-value={1024}>
        <div className="num">1</div>
        <div className="num">0</div>
        <div className="num">2</div>
        <div className="num">4</div>
      </div>
      <p>People Already Signed Up</p>
   
    </div>
  </section>
  {/* Download Section
    ================================================= */}
  {/* Image Divider
    ================================================= */}
  <div className="img-divider hidden-sm hidden-xs" />
  {/* Facts Section
    ================================================= */}
  <section id="site-facts">
    <div className="container wrapper">
      <div className="circle" style={{backgroundColor:"#FF7A7B"}}>
        <ul className="facts-list">
          <li>
            <div className="fact-icon">
              <i className="icon ion-ios-people-outline" />
            </div>
            <h3 className="text-white">1,01,242</h3>
            <p>People registered</p>
          </li>
          <li>
            <div className="fact-icon">
              <i className="icon ion-images" />
            </div>
            <h3 className="text-white">21,01,242</h3>
            <p>Posts published</p>
          </li>
          <li>
            <div className="fact-icon">
              <i className="icon ion-checkmark-round" />
            </div>
            <h3 className="text-white">41,242</h3>
            <p>People online</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>   
        </>
    )
}

export default HomeBody
