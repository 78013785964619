import React from 'react'


import {NavLink} from 'react-router-dom'
import Logo from '../../logo.png'


const DashboardHeader = (props) => {
    return (
        <>
          <header id="header" className="visible">
  <nav className="navbar navbar-default navbar-fixed-top menu">
    <div className="container">
      {/* Brand and toggle get grouped for better mobile display */}
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
          aria-expanded="false"
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <NavLink className="navbar-brand" to="">
          <img src={Logo} alt="logo" width="70%" />
        </NavLink>
      </div>
      {/* Collect the nav links, forms, and other content for toggling */}
      <div
        className="collapse navbar-collapse"
        id="bs-example-navbar-collapse-1"
      >
        <ul className="nav navbar-nav navbar-right main-menu">
          <li className="dropdown">
            <a onClick={() =>  props.changePage(4) }>NewsFeed</a>
          </li>

          
          <li className="dropdown">
            <a onClick={() =>  props.changePage(0) }>My Timeline</a>
          </li>
          <li className="dropdown">
          <a onClick={() =>  props.changePage(1) }>1,299 Followers</a>
          </li>


          <li className="dropdown">
            <a onClick={() =>  props.changePage(2) }>1,699 Following</a>
          </li>
          <li className="dropdown">
            <a onClick={() =>  props.changePage(3) }>Edit Profile</a>
          </li>
          
        </ul>
      </div>
      {/* /.navbar-collapse */}
    </div>
    {/* /.container */}
  </nav>
</header>
  
        </>
    )
}

export default DashboardHeader
