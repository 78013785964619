import React from 'react'

import blackLogo from '../blackLogo.png'

const HomeFooter = () => {
    return (
        <>
          <footer id="footer">
  <div className="container">
    <div className="row">
      <div className="footer-wrapper">
        <div className="col-md-3 col-sm-3">
          <a href="#">
            <img src={blackLogo} alt="Logo" className="footer-logo" width="60%" />
          </a>
          <ul className="list-inline social-icons">
            <li>
              <a href="#">
                <i className="icon ion-social-facebook" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="icon ion-social-twitter" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="icon ion-social-googleplus" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="icon ion-social-pinterest" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="icon ion-social-linkedin" />
              </a>
            </li>
          </ul>
        </div>
        
       
       <div className="col-md-4 col-sm-4">
          <h5>About</h5>
          <ul className="footer-links">
            <li>
              <a href="#">About us</a>
            </li>
            <li>
              <a href="#">Contact us</a>
            </li>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
            <li>
              <a href="#">Terms</a>
            </li>
            <li>
              <a href="#">Help</a>
            </li>
          </ul>
        </div>
        <div className="col-md-3 col-sm-3">
          <h5>Contact Us</h5>
          <ul className="contact">
            <li>
              <i className="icon ion-ios-telephone-outline" />
              +1 (234) 222 0754
            </li>
            <li>
              <i className="icon ion-ios-email-outline" />
              info@trafficate.net
            </li>
            <li>
              <i className="icon ion-ios-location-outline" />
              Lagos, Nigeria
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="copyright">
    <p>Trafficate © 2020. All rights reserved</p>
  </div>
</footer>;
  
        </>
    )
}

export default HomeFooter
