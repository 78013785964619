import React from 'react'

import userImg from '../../../user.jpg'
import bgImg from '../../../bg.jpg'


const LeftSidebar = () => {
    return (
        <div className="col-md-2 static">
        <div className="suggestions" id="sticky-sidebar" style={{}}>
          <h4 className="grey">Who to Follow</h4>
          <div className="follow-user">
            <img
              src={userImg}
              alt
              className="profile-photo-sm pull-left"
            />
            <div>
              <h5>
                <a href="timeline.html">Real Skillz</a>
              </h5>
              <a href="#" className="text-green">
                Follow
              </a>
            </div>
          </div>
          <div className="follow-user">
            <img
              src={userImg}
              alt
              className="profile-photo-sm pull-left"
            />
            <div>
              <h5>
                <a href="timeline.html">Dele Otedola</a>
              </h5>
              <a href="#" className="text-green">
                Follow
              </a>
            </div>
          </div>
       

        </div>
      </div>
      
    )
}

export default LeftSidebar
