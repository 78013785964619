import React from 'react'

import {NavLink} from 'react-router-dom'

import Logo from '../../logo.png'
import userImg from '../../user.jpg'

const TimelineHeader = (props) => {
    return (
        <div className="container">
            <div className="timeline-cover">
  {/*Timeline Menu for Large Screens*/}
  <div className="timeline-nav-bar hidden-sm hidden-xs">
    <div className="row">
      <div className="col-md-3">
        <div className="profile-info">
          <img
            src={userImg}
            alt = ""
            className="img-responsive profile-photo"
          />
          <h3>My name</h3>
          <p className="text-muted">username - ifepopson</p>
        </div>
      </div>
      <div className="col-md-9">
        <ul className="list-inline profile-menu">
        <li>
            <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(4)}>NewsFeed</button>
          </li>
          <li>
          <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(0)}>My Timeline</button>
          </li>
          <li>
            <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(1)}> 1,299 Followers</button>
          </li>
          <li>
            <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(2) }> 1,699 Following</button>
          </li>
        </ul>
        <ul className="follow-me list-inline">
          <li>
           <button className="btn btn-danger" onClick={() =>  props.changePage(3) }>Edit Profile</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  {/*Timeline Menu for Large Screens End*/}
  {/*Timeline Menu for Small Screens*/}
  <div className="navbar-mobile hidden-lg hidden-md">
    <div className="profile-info">
      <img
        src="images/users/user-1.jpg"
        alt = ""
        className="img-responsive profile-photo"
      />
      <h4>Sarah Cruiz</h4>
      <p className="text-muted">Creative Director</p>
    </div>
    <div className="mobile-menu">
    <ul className="list-inline">
    <li>
            <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(0)}>NewsFeed</button>
          </li>
          <li>
            <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(0)}>My Timeline</button>
          </li>
          <li>
            <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(1) }> 1,299 Followers</button>
          </li>
          <li>
            <button type="button" class="btn btn-link" style={{ color:'white' }} onClick={() =>  props.changePage(2) }> 1,699 Following</button>
          </li>
        </ul>
      <button className="btn btn-danger" onClick={() =>  props.changePage(3) }>Edit Profile</button>
    </div>
  </div>
  {/*Timeline Menu for Small Screens End*/}
</div>

        </div>
    )
}

export default TimelineHeader
