import React,{useState} from 'react'

// import HomeHeader from '../../components/HomeHeader'
import HomeFooter from '../../components/HomeFooter'
import DashboardHeader from '../../components/dashboard/DashboardHeader'
import TimelineHeader from '../../components/dashboard/TimelineHeader'
import TimelineBody from '../../components/dashboard/TimelineBody'

import FriendsList from '../../components/dashboard/FriendsList'
import EditProfile from './EditProfile'

import NewsFeed from'./NewsFeed'

const DashboardHome = () => {

    const[subPage,setSubPage] = useState(0)

   
    const changePage = (value) =>{
        // alert("Page changed - "+value)
        setSubPage(value);
    }

    let pageContent;

    if(subPage === 0){
        pageContent = <TimelineBody />
    }else if(subPage === 1){
        pageContent = <FriendsList />
    }else if(subPage === 2){
        pageContent = <FriendsList />
    }else if(subPage === 3){
        pageContent = <EditProfile/>
    }else if(subPage === 4){
        pageContent = <NewsFeed/>
    }else{

    }
    return (
        <>
            <DashboardHeader changePage={changePage}/>  
            <TimelineHeader changePage={changePage}/>
            {pageContent}

            <HomeFooter/> 
        </>
    )
}

export default DashboardHome
