import React from 'react'

import DashboardHeader from '../../components/dashboard/DashboardHeader'

import RightSidebar from '../../components/dashboard/newsfeed/RightSidebar'
import LeftSidebar from '../../components/dashboard/newsfeed/LeftSidebar'

import userImg from '../../user.jpg'
import bgImg from '../../bg.jpg'


const NewsFeed = () => {
    return (
        <>
        <DashboardHeader />
        <div className="page-contents" style={{marginTop:"30px"}}>

       
        <div className="container">
           
            <RightSidebar/>

            <div className="col-md-7">
  {/* Post Create Box
      ================================================= */}
  <div className="create-post">
    <div className="row">
      <div className="col-md-7 col-sm-7">
        <div className="form-group">
          <img src={userImg} alt className="profile-photo-md" />
          <textarea
            name="texts"
            id="exampleTextarea"
            cols={30}
            rows={1}
            className="form-control"
            placeholder="Write what you wish"
            defaultValue={""}
          />
        </div>
      </div>
      <div className="col-md-5 col-sm-5">
        <div className="tools">
          <ul className="publishing-tools list-inline">
            <li>
              <a href="#">
                <i className="ion-compose" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ion-images" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ion-ios-videocam" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="ion-map" />
              </a>
            </li>
          </ul>
          <button className="btn btn-primary pull-right">Publish</button>
        </div>
      </div>
    </div>
  </div>
  {/* Post Create Box End*/}
  {/* Post Content
      ================================================= */}
  <div className="post-content">
    <img
      src={bgImg}
      alt="post-image"
      className="img-responsive post-image"
    />
    <div className="post-container">
      <img
        src={userImg}
        alt="user"
        className="profile-photo-md pull-left"
      />
      <div className="post-detail">
        <div className="user-info">
          <h5>
            <a href="timeline.html" className="profile-link">
              Alexis Clark
            </a>{" "}
            <span className="following">following</span>
          </h5>
          <p className="text-muted">Published a photo about 3 mins ago</p>
        </div>
        <div className="line-divider" />
        <div className="post-text">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.{" "}
            <i className="em em-anguished" /> <i className="em em-anguished" />{" "}
            <i className="em em-anguished" />
          </p>
        </div>
        <div className="line-divider" />
       
      </div>
    </div>
  </div>
  {/* Post Content
      ================================================= */}
</div>


            <LeftSidebar/>
           
        </div> 
        
        </div>
        </>

    )
}

export default NewsFeed
