import React,{useState} from 'react'

import HomeHeader from '../components/HomeHeader'
import HomeForm from '../components/HomeForm'
import HomeBody from '../components/HomeBody'
import HomeFooter from '../components/HomeFooter'


const Home = () => {
    const [isLoginForm,setLoginForm] = useState(false)

    const changeForm =()=>{
        setLoginForm(!isLoginForm);
    }

    return (
        <>
          <HomeHeader changeFormType={changeForm}  islogin={isLoginForm}/>  
          <HomeForm changeFormType={changeForm}  islogin={isLoginForm}/>  
          <HomeBody/> 
          <HomeFooter/>  
        </>
    )
}

export default Home
