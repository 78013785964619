import React from 'react'

import userImg from '../../../user.jpg'
import bgImg from '../../../bg.jpg'

import {NavLink} from 'react-router-dom'

const RightSidebar = () => {
    return (
        <div className="col-md-3 static">
        
        {/*profile card ends*/}
        <ul className="nav-news-feed">
        <li>
            <i className="icon ion-ios-paper" />
            <div>
              <NavLink to="/dashboard/home">Profile</NavLink>
            </div>
          </li>
          <li>
            <i className="icon ion-ios-paper" />
            <div>
              <a href="newsfeed.html">My Newsfeed</a>
            </div>
          </li>
          <li>
            <i className="icon ion-ios-people-outline" />
            <div>
              <a href="newsfeed-friends.html">Followers</a>
            </div>
          </li>
          <li>
            <i className="icon ion-images" />
            <div>
              <a href="newsfeed-images.html">Posts</a>
            </div>
          </li>
        </ul>
        {/*news-feed links ends*/}
        {/*chat block ends*/}
      </div>
      
    )
}

export default RightSidebar
